<template>
  <div>
    <hr v-if="!spinner">
    <b-spinner
        v-if="spinner"
        variant="primary"
        class="spinners"
    />
    <div v-if="!spinner">
      <b-row class="mt-1">
        <b-col lg="5" md="12" sm="12" cols="12">
          <b-card-actions
              class="cari-liste-card"
          >
            <b-row class="mt-50 mb-3">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">Dinamik Sayfa Listesi</h4>
                </b-col>
              </div>
            </b-row>
            <hr class="mt-50">
            <b-table
                class="table-striped mt-2"
                empty-text="Veri Bulunamadı"
                striped
                hover
                :items="salaryListItems.slice().reverse()"
                :fields="fields"
            >
              <template #cell(actions)="row">
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-modal.galleryDetails
                    variant="bgSuccess"
                    class="btn-icon rounded-circle mr-1"
                    size="sm"
                    @click="galleryDetails(row.item)"
                >
                  <feather-icon icon="ImageIcon"/>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-modal.pageEdit
                    variant="bgInfo"
                    class="btn-icon rounded-circle mr-1"
                    size="sm"
                    @click="sayfaUpdateRun(row.item)"
                >
                  <feather-icon icon="EditIcon"/>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="bgDanger"
                    class="btn-icon rounded-circle mr-1"
                    size="sm"
                    @click="pageRemove(row.item)"
                >
                  <feather-icon icon="TrashIcon"/>
                </b-button>
              </template>
            </b-table>
          </b-card-actions>
        </b-col>
        <b-col lg="7" md="12" sm="12" cols="12">
          <b-card-actions
              class="cari-liste-card"
          >
            <b-row class="mt-50 mb-3">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">Sayfa Kaydı</h4>
                </b-col>
              </div>
            </b-row>
            <hr class="mt-50">
            <b-form-group
                label="Sayfa Adı"
                label-for="basicInput"
                class="mt-1"
            >
              <b-form-input
                  id="basicInput"
                  v-model="pageName"
                  autocomplete="off"
              />
            </b-form-group>
            <b-form-group label="Sayfa Türü">
              <v-select
                  v-model="tur"
                  :components="{ OpenIndicator, Deselect }"
                  label="title"
                  :options="option"
              />
            </b-form-group>
            <b-form-group
                label="Sayfa İçeriği"
                label-for="basicInput"
                class="mt-2"
            >
              <quill-editor
                  v-model="content"
                  :options="snowOption"
              />
            </b-form-group>
            <b-row>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgInfo"
                  class="mt-1 mr-1 border-0"
                  :style="{backgroundColor:$store.getters.bgSuccess}"
                  @click="sayfaSave"
                  :disabled="!pageName"
              >
                Kaydet
              </b-button>
            </b-row>
          </b-card-actions>
        </b-col>
      </b-row>
    </div>
    <b-modal
        id="pageEdit"
        cancel-variant="secondary"
        ok-only
        ok-title="Güncelle"
        ok-variant="bgInfo"
        centered
        size="lg"
        title="Sayfayı Düzenle"
        @ok="pageUpdate"
    >
      <b-form-group
          label="Sayfa Adı"
          label-for="basicInput"
      >
        <b-form-input
            id="basicInput"
            v-model="sayfaUname"
            @keyup.enter="pageUpdate"
            autocomplete="off"
        />
      </b-form-group>
      <b-form-group
          label="Sayfa Adı"
          label-for="basicInput"
      >
        <v-select
            v-model="sayfaUhizmet"
            :components="{ OpenIndicator, Deselect }"
            label="title"
            :options="option"
            autocomplete="off"
        />
      </b-form-group>
      <b-form-group
          label="Sayfa İçeriği"
          label-for="basicInput"
      >
        <quill-editor v-model="sayfaUicerik"/>
      </b-form-group>
    </b-modal>
    <b-modal
        id="galleryDetails"
        ok-only
        ok-title="Gizle"
        centered
        size="xl"
        title="Galeri Detay"
        ok-variant="bgInfo"
        @hide="clearDetails"
    >
      <b-row>
        <b-col lg="8" md="12" sm="12" cols="12">
          <b-card-actions
              class="cari-liste-card"
          >
            <b-row class="mt-50 mb-3">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">{{ sayfaUname }} Adlı Sayfaya Ait
                    Resimler</h4>
                </b-col>
              </div>
            </b-row>
            <hr class="mt-50">
            <table class="table table-striped">
              <thead>
              <tr>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  Foto Önizleme
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  Foto Açıklaması
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"/>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(item, index) in $store.getters.pageGaleriDetayReturn
                    .liste"
                  :key="index"
              >
                <td>
                  <b-img
                      :src="imagePath(item.foto)"
                      fluid
                      alt="Responsive image"
                  />
                </td>
                <td>{{ item.aciklama }}</td>
                <td>
                  <b-button
                      variant="bgDanger"
                      class="btn-icon rounded-circle"
                      @click="galleryDetailsRemove(item.fotoID)"
                  >
                    <feather-icon icon="TrashIcon"/>
                  </b-button>
                </td>
              </tr>
              </tbody>
            </table>
            <p class="mt-2">
              Toplam
              {{ galeriCount }} Kayıt Listeleniyor
            </p>
          </b-card-actions>
        </b-col>
        <b-col lg="4" md="12" sm="12" cols="12">
          <b-card-actions
              class="cari-liste-card"
          >
            <b-row class="mt-50 mb-3">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">Resim Yükle</h4>
                </b-col>
              </div>
            </b-row>
            <hr class="mt-50">
            <b-form-group
                label="Dosya Açıklaması"
                label-for="basicInput"
                class="mt-1"
            >
              <b-form-input
                  id="basicInput"
                  v-model="detailsAciklama"
                  autocomplete="off"
              />
            </b-form-group>
            <b-form-group
                label="Lütfen Dosya Seçiniz"
                label-for="basicInput"
            >
              <b-form-file
                  :key="count"
                  type="file"
                  placeholder="Dosya Seçiniz"
                  drop-placeholder="Drop file here..."
                  accept=".jpg, .jpeg"
                  :file-name-formatter="formatNames"
                  multiple
                  @change="onFileSelected"
              />
            </b-form-group>
          </b-card-actions>
          <b-row>
            <b-col cols="12">
              <b-progress
                  v-model="value"
                  max="100"
              />
            </b-col>
            <b-col
                cols="12"
                class="text-right"
            >
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="border-0 mt-50"
                  variant="bgInfo"
                  :style="{backgroundColor:$store.getters.bgSuccess}"
                  :disabled="this.detailsAciklama == ''"
                  @click="onUpload"
              >
                Kaydet
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BProgress,
  BImg,
  BRow,
  BCol,
  BCard,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormCheckbox,
  BFormCheckboxGroup,
  BTable,
  BModal,
  BInputGroup,
  BContainer,
  BFormInput,
  BPagination,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton, BSpinner,
  BFormFile,
} from 'bootstrap-vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    BCardActions,
    BProgress,
    BImg,
    BButton,
    BSpinner,
    BContainer,
    BAvatar,
    BCard,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormCheckbox,
    BFormCheckboxGroup,
    BTable,
    BModal,
    BInputGroup,
    BFormFile,
    vSelect,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      count: 0,
      spinner: true,
      Deselect: {
        render: createElement => createElement('span', ''),
      },
      OpenIndicator: {
        render: createElement => createElement('span', ''),
      },
      value: 0,
      detailsAciklama: '',
      pageName: '',
      snowOption: {
        backgroundColor: 'red',
        placeholder: '',
        color: 'red',
      },
      tur: {
        title: 'Dinamik Sayfa',
        key: 0
      },
      option: [
        {
          title: 'Dinamik Sayfa',
          key: 0
        },
        {
          title: 'Hizmet Sayfası',
          key: 1
        },
      ],
      content: '',
      fields: [
        {
          key: 'sayfaAdi',
          label: 'Sayfa Adı',
          sortable: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'link',
          label: 'Sayfa Linki',
          sortable: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
      ],
      sayfaUname: '',
      sayfaUid: '',
      sayfaUicerik: '',
      sayfaUhizmet: {},
      selectedFile: [],
    }
  },
  computed: {
    galeriCount() {
      if (this.$store.getters.pageGaleriDetayReturn.liste) {
        return this.$store.getters.pageGaleriDetayReturn.liste.length
      }
    },
    salaryListItems() {
      return this.$store.state.webPanel.dinamikSayfalar
    },
  },
  created() {
    this.$store.dispatch('dinamikSayfalar')
        .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: mesaj,
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            }, { position })
          }
        })
        .catch(err => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  beforeDestroy() {
    this.$store.commit('clearDinamikSayfalar')
  },
  methods: {
    imagePath(path) {
      return `${this.$store.state.API_URL}/userFiles/${localStorage.getItem('otelFirmaKodu')}/Galeri/kucuk/${path}`
    },
    onUpload() {
      const aciklama = this.detailsAciklama
      this.detailsAciklama = ''
      const fd = new FormData()
      this.selectedFile.forEach(element => {
        fd.append('resim[]', element)
      })
      fd.append('crm_token', localStorage.getItem('otelUserToken'))
      fd.append('serviceName', 'web')
      fd.append('methodName', 'sayfaResimleri')
      fd.append('aciklama', aciklama)
      fd.append(
          'dinamikID',
          this.$store.getters.pageGaleriDetayReturn.sayfa.dinamikID,
      )
      fd.append('fotoKaydi', true)
      axios
          .post(store.state.POST_URL, fd, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: function (progressEvent) {
              this.value = parseInt(
                  Math.round((progressEvent.loaded / progressEvent.total) * 95),
              )
            }.bind(this),
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            if (res.data.result.status == 200) {
              this.$store.dispatch('pageGaleriDetay', {
                dinamikID:
                this.$store.getters.pageGaleriDetayReturn.sayfa.dinamikID,
              })
              this.selectedFile = []
              this.count++
              this.detailsAciklama = '';
              (this.value = 0)
              const mesaj = this.$store.getters.notificationSettings(res.data)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
              }
            } else {
              (this.value = 0)
              const mesaj = this.$store.getters.notificationSettings(res.data)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            (this.value = 0)
            const mesaj = this.$store.getters.notificationSettings(err.data)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    galleryDetails(val) {
      this.sayfaUname = val.sayfaAdi
      this.$store.dispatch('pageGaleriDetay', {
        dinamikID: val.dinamikID,
      })
    },
    pageUpdate() {
      this.$store
          .dispatch('pageUpdate', {
            pageID: this.sayfaUid,
            sayfaAdi: this.sayfaUname,
            hizmet: this.sayfaUhizmet.key,
            icerik: this.sayfaUicerik,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            this.$bvModal.hide('pageEdit')
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: mesaj,
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            }, { position })
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    pageRemove(val) {
      this.$store
          .dispatch('pageRemove', { sayfaSil: val.dinamikID })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: mesaj,
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            }, { position })
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    sayfaSave() {
      this.$store
          .dispatch('pageSave', {
            sayfaAdi: this.pageName,
            hizmet: this.tur.key,
            icerik: this.content,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            (this.pageName = ''),
                (this.content = ''),
                (this.tur = {
                  title: 'Dinamik Sayfa',
                  key: 0
                })
            const mesaj = this.$store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            }
            this.addRezervasyon.isim = ''
            this.addRezervasyon.soyIsim = ''
            this.addRezervasyon.tcKimlik = ''
            this.addRezervasyon.tel = ''
            this.addRezervasyon.cinsiyet = ''
            this.addRezervasyon.rezervasyon = 'Normal'
            this.addRezervasyon.tarife = ''
            this.addRezervasyon.ucret = ''
            this.addRezervasyon.girisTarih = ''
            this.addRezervasyon.cikisTarih = ''
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    sayfaUpdateRun(v) {
      this.sayfaUname = v.sayfaAdi
      this.sayfaUid = v.dinamikID
      this.sayfaUicerik = v.icerik
      v.hizmet == 0
          ? (this.sayfaUhizmet = {
            title: 'Dinamik Sayfa',
            key: 0
          })
          : (this.sayfaUhizmet = {
            title: 'Hizmet Sayfası',
            key: 1
          })
    },
    clearDetails() {
      this.$store.commit('clearPageGaleriDetay')
      this.detailsAciklama = ''
      this.selectedFile = []
    },
    galleryDetailsRemove(val) {
      this.$store
          .dispatch('pageGalleryDetailsRemove', {
            dinamikID: this.$store.getters.pageGaleriDetayReturn.sayfa.dinamikID,
            sil: val,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: mesaj,
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            }, { position })
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                tet: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    formatNames(files) {
      return files.length === 1
          ? files[0].name
          : `${files.length} resim seçildi`
    },
    onFileSelected(event) {
      event.target.files.forEach((image, index) => {
        this.selectedFile.push(image)
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
[dir] .table th,
[dir] .table td {
  padding: 3px !important;
}

.ql-container {
  height: 250px;
}
</style>
<style scoped>
.row {
  justify-content: end !important;
}

[dir] .table th,
[dir] .table td {
  border-top: 1px solid #ebe9f1;
  max-width: 450px !important;
  padding: 3px !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

img {
  width: 30% !important;
}

.card-body {
  padding: 10px 15px;
}
</style>
